<template>
  <div>
    <location />
    <div class="page">
      <swiper
        :options="{
          slidesPerView: 'auto',
          freeMode: true,
          //initialSlide: tab - 1,
        }"
        class="swiper-tab-main"
      >
        <swiper-slide :class="{ active: searchData.pointtype == '1' }"
          ><div @click="change_type(1)">
            {{ $t("button.common.oom_point") }}
          </div></swiper-slide
        >
        <swiper-slide :class="{ active: searchData.pointtype == '2' }"
          ><div @click="change_type(2)">
            {{ $t("button.common.standard_point") }}
          </div></swiper-slide
        >
        <swiper-slide :class="{ active: searchData.pointtype == '3' }"
          ><div @click="change_type(3)">
            {{ $t("button.common.player_badge") }}
          </div></swiper-slide
        >
      </swiper>
      <ul class="nav nav-pills wrap-1200" id="pills-tab" role="tablist">
        <li class="nav-item col-4" role="presentation">
          <button
            class="w-100 nav-link"
            @click="change_type(1)"
            :class="{
              active: searchData.pointtype == '1',
            }"
          >
            {{ $t("button.common.oom_point") }}
          </button>
        </li>
        <li class="nav-item col-4" role="presentation">
          <button
            class="w-100 nav-link"
            @click="change_type(2)"
            :class="{
              active: searchData.pointtype == '2',
            }"
          >
            {{ $t("button.common.standard_point") }}
          </button>
        </li>
        <li class="nav-item col-4" role="presentation">
          <button
            class="w-100 nav-link"
            @click="change_type(3)"
            :class="{
              active: searchData.pointtype == '3',
            }"
          >
            {{ $t("button.common.player_badge") }}
          </button>
        </li>
      </ul>

      <div class="wrap-1200 mt-3 pt-3 mt-lg-4 pt-lg-3">
        <div v-if="searchData.pointtype == '1'">
          <h3
            class="subtitle mt-0"
            v-html="$t('text.halloffame.main_title_1')"
          ></h3>
          <div class="row row-cols-1 g-3 lh-base">
            <div class="col">
              {{ $t("text.halloffame.sub_title_1") }}
            </div>
            <div class="col">
              {{ $t("text.halloffame.sub_title_2") }}
            </div>
            <div class="col">
              {{ $t("text.halloffame.sub_title_3") }}
            </div>
            <div class="col">
              {{ $t("text.halloffame.sub_title_4") }}
            </div>
            <div class="col">
              {{ $t("text.halloffame.sub_title_5") }}
            </div>
          </div>
        </div>

        <div v-if="searchData.pointtype == '2'">
          <h3
            class="subtitle mt-0"
            v-html="$t('text.halloffame.main_title_2')"
          ></h3>
          <div class="row row-cols-1 g-3 lh-base">
            <div class="col">
              {{ $t("text.halloffame.sub_title_6") }}
            </div>
            <div class="col">
              {{ $t("text.halloffame.sub_title_7") }}
            </div>
            <div class="col">
              {{ $t("text.halloffame.sub_title_8") }}
            </div>
            <div class="col">
              {{ $t("text.halloffame.sub_title_9") }}
            </div>
          </div>

          <hr class="my-5">

          <h3
            class="subtitle mt-0"
            v-html="$t('text.halloffame.main_title_3')"
          ></h3>
          <div class="row row-cols-1 g-3 lh-base">
            <div class="col">
              {{ $t("text.halloffame.sub_title_10") }}
            </div>
            <div class="col">
              {{ $t("text.halloffame.sub_title_11") }}
            </div>
            <div class="col">
              {{ $t("text.halloffame.sub_title_12") }}
            </div>
            <div class="col">
              {{ $t("text.halloffame.sub_title_13") }}
            </div>
          </div>
        </div>

        <div v-if="searchData.pointtype == '3'">
          <!-- <h3 class="subtitle mt-0">
            {{ $t("text.halloffame.main_title_4") }}
          </h3>
          <div class="row row-cols-1 g-3 lh-base">
            <div class="col">
              {{ $t("text.halloffame.sub_title_14") }}
            </div>
            <div class="col">
              {{ $t("text.halloffame.sub_title_15") }}
            </div>
            <div class="col">
              {{ $t("text.halloffame.sub_title_16") }}
            </div>
          </div>

          <hr class="my-5">
           -->

          <!-- <h3 class="subtitle mt-0">
            {{ $t("text.common.xbadge") }}
          </h3> -->

          <div class="badge-expl row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4 justify-content-md-center text-center">
            <div class="col">
              <div class="card h-100">
                <img src="/img/badge_xgolf_longdrive_f.svg" class="align-self-center p-5 pb-0">
                <div class="card-body">
                  <img src="/img/badge_xgolf_longdrive_t.svg" class="avatar avatar-lg mb-2 border-0">
                  <h6 class="card-title" title="Long-Drive Badge">{{ $t("text.common.long_drive") }} {{ $t("text.common.badge") }}</h6>
                  <small class="text-black">{{ $t("text.common.long_drive_expl") }}</small>                  
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card h-100">
                <img src="/img/badge_xgolf_accuracy_f.svg" class="align-self-center p-5 pb-0">
                <div class="card-body">
                  <img src="/img/badge_xgolf_accuracy_t.svg" class="avatar avatar-lg mb-2 border-0">
                  <h6 class="card-title" title="Accuracy Badge">{{ $t("text.common.accuracy") }} {{ $t("text.common.badge") }}</h6>
                  <small class="text-black">{{ $t("text.common.accuracy_expl") }}</small>                  
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card h-100">
                <img src="/img/badge_xgolf_tourrookie_f.svg" class="align-self-center p-5 pb-0">
                <div class="card-body">
                  <img src="/img/badge_xgolf_tourrookie_t.svg" class="avatar avatar-lg mb-2 border-0">
                  <h6 class="card-title" title="Tour-Rookie Badge">{{ $t("text.common.tour_rookie") }} {{ $t("text.common.badge") }}</h6>
                  <small class="text-black">{{ $t("text.common.tour_rookie_expl") }}</small>
                </div>

              </div>
            </div>

            <div class="col">
              <div class="card h-100">
                <img src="/img/badge_xgolf_shooter_f.svg" class="align-self-center p-5 pb-0">
                <div class="card-body">
                  <img src="/img/badge_xgolf_shooter_t.svg" class="avatar avatar-lg mb-2 border-0">
                  <h6 class="card-title" title="Shooter Badge">{{ $t("text.common.shooter") }} {{ $t("text.common.badge") }}</h6>
                  <small class="text-black">{{ $t("text.common.shooter_expl") }}</small>                  
                </div>
              </div>
            </div>                      

            <div class="col">
              <div class="card h-100">
                <img src="/img/badge_xgolf_glory_f.svg" class="align-self-center p-5 pb-0">
                <div class="card-body">
                  <img src="/img/badge_xgolf_glory_t.svg" class="avatar avatar-lg mb-2 border-0">
                  <h6 class="card-title" title="Glory Badge">{{ $t("text.common.glory") }} {{ $t("text.common.badge") }}</h6>
                  <small class="text-black">{{ $t("text.common.glory_expl") }}</small>
                </div>
              </div>
            </div>  
          </div><!-- // badge-expl -->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import location from "@/components/Location";

import { myMixin } from "@/mixin/myMixin";

export default {
  name: "PointInfo",
  components: {
    Swiper,
    SwiperSlide,
    location,
  },
  data() {
    return {
      searchData: {
        pointtype:
          this.$route.query.pointtype ||
          (this.$store.state.searchData[this.$route.matched[0].name] &&
            this.$store.state.searchData[this.$route.matched[0].name]
              .pointtype) ||
          "1",
      },
    };
  },
  methods: {
    change_type(type) {
      this.searchData.pointtype = type;
    },
  },
  created() {},
  watch: {},

  mixins: [myMixin],
};
</script>