<template>
  <div>
    <location />
    <div class="page">
      <swiper
        :options="{
          slidesPerView: 'auto',
          freeMode: true,
          //initialSlide: tab - 1,
        }"
        class="swiper-tab-main"
      >
        <swiper-slide :class="{ active: searchData.pointtype == '1' }"
          ><div @click="change_type(1)">
            {{ $t("button.common.oom_point") }}
          </div></swiper-slide
        >
        <swiper-slide :class="{ active: searchData.pointtype == '2' }"
          ><div @click="change_type(2)">
            {{ $t("button.common.standard_point") }}
          </div></swiper-slide
        >
      </swiper>
      <ul class="nav nav-pills wrap-1200" id="pills-tab" role="tablist">
        <li class="nav-item col-6" role="presentation">
          <button
            class="w-100 nav-link"
            @click="change_type(1)"
            :class="{
              active: searchData.pointtype == '1',
            }"
          >
            {{ $t("button.common.oom_point") }}
          </button>
        </li>
        <li class="nav-item col-6" role="presentation">
          <button
            class="w-100 nav-link"
            @click="change_type(2)"
            :class="{
              active: searchData.pointtype == '2',
            }"
          >
            {{ $t("button.common.standard_point") }}
          </button>
        </li>
      </ul>

      <div class="wrap-1200">
        <div
          class="d-flex justify-content-center align-items-center mt-3 mt-lg-4 mb-2"
        >
          <button class="btn p-0" @click="prev()">
            <i class="material-icons fs-1">keyboard_arrow_left</i>
          </button>
          <h5 class="mx-2 text-center">
            {{ searchData.searchdate }} {{ $t("text.common.season") }}
          </h5>
          <button class="btn p-0" @click="next()">
            <i class="material-icons fs-1">keyboard_arrow_right</i>
          </button>
        </div>

        <div class="season-ranking-top3 px-md-4 px-lg-5">
          <div class="row row-cols-1 row-cols-sm-3 g-3 g-sm-4">
            <template v-for="(item, index) in topitems">
              <div :key="'a' + index" class="col">
                <div class="card" :class="'rank-' + item.ranking">
                  <div class="card-body text-center">
                    <div class="position-absolute top-0 start-0 award-tag">
                      {{ $t("title.common.top_rank") }}
                      <p class="fw-bold">{{ item.ranking }}</p>
                    </div>

                    <div class="award-circle">
                      <div class="avatar">
                        <img
                          v-if="item.badge_img"
                          :src="item.badge_img | get_img"
                        />

                        <img
                          v-else-if="item.member_img"
                          :src="item.member_img | get_img"
                        />
                        <span v-else class="material-icons default-icon-profile"
                          >person</span
                        >
                      </div>
                    </div>

                    <div class="my-2 lh-base">
                      <p class="text-nickname">
                        <MemberInfo
                          :nickname="item.nickname"
                          :playerno="item.player_pk"
                          :no_img="true"
                        />
                      </p>
                      <p class="fs-12 shop-name">
                        {{ item.shopname || "-" }}
                      </p>
                    </div>

                    <h2
                      class="text-point-op"
                      v-if="searchData.pointtype == '1'"
                    >
                      <!-- <p class="fs-13 fw-normal">{{ $t("title.common.oom_point") }}</p> -->
                      {{ item.poi_point | comma | suffix("P") }}
                    </h2>
                    <h2
                      class="text-point-sp"
                      v-if="searchData.pointtype == '2'"
                    >
                      <!-- <p class="fs-13 fw-normal">{{ $t("title.common.standard_point") }}</p> -->
                      {{ item.poi_point | comma | suffix("P") }}
                    </h2>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>

        <b-form
          @submit="onSubmit"
          class="filter-group row row-cols-2 row-cols-sm-auto g-2 justify-content-end mt-4"
        >
          <div class="col">
            <select aria-label="" v-model="searchData.pagesize">
              <option value="10" selected>
                {{ $t("filter.common.sort_by_10") }}
              </option>
              <option value="50">{{ $t("filter.common.sort_by_50") }}</option>
              <option value="100">
                {{ $t("filter.common.sort_by_100") }}
              </option>
            </select>
          </div>

          <div class="col">
            <select aria-label="Select one" v-model="searchData.searchtype">
              <option value="P.nickname">
                {{ $t("filter.common.nickname") }}
              </option>
              <option value="O.nickname">
                {{ $t("filter.common.location") }}
              </option>
            </select>
          </div>
          <div class="col-12">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                name="searchname"
                v-model="searchData.searchname"
              />
              <button
                class="btn btn-primary btn-md icon-search"
                type="submit"
              ></button>
            </div>
          </div>
        </b-form>

        <table class="table board">
          <thead v-if="searchData.pointtype == '1'">
            <tr>
              <th class="col-auto col-lg-1 d-lg-table-cell">
                {{ $t("title.common.ranking") }}
              </th>
              <th class="col-auto d-lg-table-cell">
                {{ $t("title.common.nickname") }}
              </th>
              <th class="col-auto d-none d-lg-table-cell">
                {{ $t("title.common.favorite_location") }}
              </th>
              <th class="col-auto d-none d-sm-table-cell">
                {{ $t("title.common.season_oom_point") }}
              </th>
              <th class="col-auto d-none d-lg-table-cell">
                {{ $t("title.common.season_standard_point") }}
              </th>

              <th class="th-arrow">&nbsp;</th>
            </tr>
          </thead>

          <thead v-if="searchData.pointtype == '2'">
            <tr>
              <th class="col-auto col-lg-1 d-lg-table-cell">
                {{ $t("title.common.ranking") }}
              </th>
              <th class="col-auto d-lg-table-cell">
                {{ $t("title.common.nickname") }}
              </th>
              <th class="col-auto d-none d-lg-table-cell">
                {{ $t("title.common.favorite_location") }}
              </th>
              <th class="col-auto d-none d-sm-table-cell">
                {{ $t("title.common.season_standard_point") }}
              </th>

              <th class="th-arrow">&nbsp;</th>
            </tr>
          </thead>
          <tbody
            v-if="
              items.filter((v) => v.ranking > 0).length > 0 &&
              searchData.pointtype == '1'
            "
          >
            <template
              v-for="(item, index) in items.filter((v) => v.ranking > 0)"
            >
              <tr :key="index">
                <td class="col-auto d-lg-table-cell lh-1">
                  <strong class="text-black">{{ item.ranking }}</strong
                  ><br />
                  <span
                    v-html="rank_comparison(item.player_pk)"
                    class="small"
                  ></span>
                </td>
                <td class="col-auto d-lg-table-cell text-start">
                  <MemberInfo
                    :nickname="item.nickname"
                    :playerno="item.player_pk"
                    :member_img="item.member_img"
                    :badge_img="item.badge_img"
                  />
                </td>
                <td class="col-auto d-none d-lg-table-cell shop-name">
                  {{ item.shopname || "-" }}
                </td>
                <td class="col-auto d-none d-sm-table-cell text-point-op">
                  {{ item.poi_point | comma | suffix("P") }}
                </td>
                <td class="col-auto d-none d-lg-table-cell text-point-sp">
                  {{ item.standard_points | comma | suffix("P") }}
                </td>
                <td class="td-arrow" @click="toggle_tr(index)">
                  <img
                    src="/img/arrow_none_collapsed.png"
                    :class="{ 'btn-toggle-arrow': view_hide === index }"
                  />
                </td>
              </tr>
              <tr
                class="d-lg-none"
                :class="{ show: view_hide === index }"
                :key="index + 'r'"
                v-if="view_hide === index"
              >
                <td colspan="5">
                  <div class="toggle-content">
                    <div class="row">
                      <div class="col-6 col-sm-4">
                        {{ $t("title.common.favorite_location") }}
                      </div>
                      <div class="col-6 col-sm-8 shop-name">
                        {{ item.shopname || "-" }}
                      </div>
                    </div>
                    <div class="row d-sm-none">
                      <div class="col-6 col-sm-4">
                        {{ $t("title.common.season_oom_point") }}
                      </div>
                      <div class="col-6 col-sm-8 text-point-op">
                        {{ item.poi_point | comma | suffix("P") }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 col-sm-4">
                        {{ $t("title.common.season_standard_point") }}
                      </div>
                      <div class="col-6 col-sm-8 text-point-sp">
                        {{ item.standard_points | comma | suffix("P") }}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
          <tbody
            v-else-if="
              items.filter((v) => v.ranking > 0).length > 0 &&
              searchData.pointtype == '2'
            "
          >
            <template
              v-for="(item, index) in items.filter((v) => v.ranking > 0)"
            >
              <tr :key="index">
                <td class="col-auto d-lg-table-cell lh-1">
                  <strong class="text-black">{{ item.ranking }}</strong>
                </td>
                <td class="col-auto d-lg-table-cell text-start">
                  <MemberInfo
                    :nickname="item.nickname"
                    :playerno="item.player_pk"
                    :member_img="item.member_img"
                    :badge_img="item.badge_img"
                  />
                </td>
                <td class="col-auto d-none d-lg-table-cell shop-name">
                  {{ item.shopname || "-" }}
                </td>
                <td class="col-auto d-none d-sm-table-cell text-point-sp">
                  {{ item.poi_point | comma | suffix("P") }}
                </td>
                <td class="td-arrow" @click="toggle_tr(index)">
                  <img
                    src="/img/arrow_none_collapsed.png"
                    :class="{ 'btn-toggle-arrow': view_hide === index }"
                  />
                </td>
              </tr>
              <tr
                class="d-lg-none"
                :class="{ show: view_hide === index }"
                :key="index + 'r'"
                v-if="view_hide === index"
              >
                <td colspan="4">
                  <div class="toggle-content">
                    <div class="row">
                      <div class="col-6 col-sm-4">
                        {{ $t("title.common.favorite_location") }}
                      </div>
                      <div class="col-6 col-sm-8 shop-name">
                        {{ item.shopname || "-" }}
                      </div>
                    </div>
                    <div class="row d-sm-none">
                      <div class="col-6 col-sm-4">
                        {{ $t("title.common.season_standard_point") }}
                      </div>
                      <div class="col-6 col-sm-8 text-point-sp">
                        {{ item.poi_point | comma | suffix("P") }}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="5" class="text-center">
                {{ $t("text.common.no_data") }}
              </td>
            </tr>
          </tbody>
        </table>
        <b-pagination-nav
          :link-gen="linkGen"
          :number-of-pages="page_total"
          v-model="searchData.pageno"
          use-router
          aria-label="Page navigation"
          v-if="page_total > 1"
          first-class="prev"
          prev-class="prev"
          next-class="next"
          last-class="next"
          hide-ellipsis
          limit="10"
        >
          <template #first-text>
            <i class="material-icons">keyboard_double_arrow_left</i>
          </template>
          <template #prev-text>
            <i class="material-icons">navigate_before</i>
          </template>
          <template #next-text>
            <i class="material-icons">navigate_next</i>
          </template>
          <template #last-text>
            <i class="material-icons">keyboard_double_arrow_right</i>
          </template>
          <template #page="{ page, active }">
            <a v-if="active">{{ page }}</a>
            <a v-else>{{ page }}</a>
          </template>
        </b-pagination-nav>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import location from "@/components/Location";

import ax from "@/api/halloffame";
import { myMixin } from "@/mixin/myMixin";
import "@/filter/common";

export default {
  name: "PointLeaderboard",
  components: {
    Swiper,
    SwiperSlide,
    location,
  },
  data() {
    return {
      searchData: {
        pointtype:
          this.$route.query.pointtype ||
          (this.$store.state.searchData[this.$route.matched[0].name] &&
            this.$store.state.searchData[this.$route.matched[0].name]
              .pointtype) ||
          "1",

        searchdate:
          this.$route.query.searchdate ||
          (this.$store.state.searchData[this.$route.matched[0].name] &&
            this.$store.state.searchData[this.$route.matched[0].name]
              .searchdate) ||
          this.$dayjs(new Date().setYear(new Date().getFullYear())).format(
            "YYYY"
          ),
        searchtype:
          this.$route.query.searchtype ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchtype) ||
          "P.nickname",
        searchname:
          this.$route.query.searchname ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchname) ||
          "",
        pageno: this.$route.query.pageno || 1,

        pagesize:
          this.$route.query.pagesize ||
          (this.$store.state.searchData[this.$route.matched[0].name] &&
            this.$store.state.searchData[this.$route.matched[0].name]
              .pagesize) ||
          "10",
      },
      page_total: 1,
      total_cnt: 0,

      items: [],
      topitems: [],
      prev_items: [],
      view_hide: null,
    };
  },
  methods: {
    prev() {
      this.searchData.searchdate--;
    },
    next() {
      this.searchData.searchdate++;
    },
    get_list() {
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      ax.get_pointlist(this.searchData, (flag, data) => {
        this.total_cnt = data.pointlistcnt;
        this.page_total = Math.ceil(
          data.pointlistcnt / this.searchData.pagesize
        );
        this.items = data.pointlist;
        this.topitems = data.toplist;
        this.prev_items = data.prev_pointlist;
        if (!flag) alert(data.message);
      });
    },
    onSubmit(event) {
      event.preventDefault();
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
    change_type(type) {
      this.searchData.pointtype = type;
    },

    rank_comparison(player_pk) {
      let cur_rank =
        this.items.find((v) => v.player_pk == player_pk).ranking || 0;
      let prev_rank =
        this.prev_items.find((v) => v.player_pk == player_pk).ranking || 0;

      if (cur_rank - prev_rank > 0) {
        return (
          "(+" +
          (cur_rank - prev_rank) +
          '<i class="material-icons" style="transform: rotate(270deg);color:red">play_arrow</i>' +
          ")"
        );
      }

      if (cur_rank - prev_rank < 0) {
        return (
          "(" +
          (cur_rank - prev_rank) +
          '<i class="material-icons" style="transform: rotate(90deg);color:blue">play_arrow</i>' +
          ")"
        );
      }
    },
  },
  created() {
    this.get_list();
  },
  watch: {
    "searchData.searchdate": function () {
      this.searchData.pageno = 1;
      this.get_list();
    },
    "searchData.searchname": function () {
      this.searchData.pageno = 1;
      this.get_list();
    },
    "searchData.pageno": function () {
      this.get_list();
    },
    "searchData.pagesize": function () {
      this.searchData.pageno = 1;
      this.get_list();
    },
    "searchData.searchtype": function () {
      this.searchData.pageno = 1;
      this.get_list();
    },
    "searchData.pointtype": function () {
      this.searchData.pageno = 1;
      this.get_list();
    },
  },

  mixins: [myMixin],
};
</script>